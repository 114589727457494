// 火车票运行报表导出
const __request = require(`./__request/__request_contentType_json`);
// 接口文档地址：
// http://38568z29x3.zicp.vip/doc.html#/yinzhilv-tmc/t-tp-refund-change-policy-controller/saveEvectionPolicyUsingPOST
const consumer_t_tp_refund_change_policy_saveEvectionPolicy = (pParameter) => {
  if (!pParameter) pParameter = {};

  const p = {
    method: 'post',
    urlSuffix: '/consumer/t-tp-refund-change-policy/saveEvectionPolicy',
    data: pParameter
  };

  return new Promise(function(resolve, reject) {
    resolve(__request(p))
  })
};

module.exports = consumer_t_tp_refund_change_policy_saveEvectionPolicy;