import DialogBox from '@/component/dialog'
import consumer_t_tp_refund_change_policy_get from '@/lib/data-service/haolv-default/consumert_tp_refund_change_policyget'
import consumer_t_tp_refund_change_policy_saveEvectionPolicy from '@/lib/data-service/haolv-default/consumer_t_tp_refund_change_policy_saveEvectionPolicy'
import consumer_admin_flight_generate_getInsuranceProductList from '@/lib/data-service/haolv-default/consumer_admin_flight_generate_getInsuranceProductList'
import consumer_web_company_checkCompanyUseCar from '@/lib/data-service/haolv-default/consumer_web_company_checkCompanyUseCar'
import {awaitWrap} from '@/page/admin/company-management/staff-management/common/unit'

export default {
  name: "evectionRule",
  data() {
    return {
      detail: {},
      dataOff: true,
      dataTest:[
        {
          id: 1,
          name:'出差单是否必填',
          data:null,
          type:1,
          isShow: true,
          text:'因公出差是否一定要申请出差单',
          option:[
            {
              value:1,
              label:'是'
            },
            {
              value:0,
              label:'否'
            }
          ]
        },
        {
          id: 14,
          name:'预算管控',
          data:null,
          type:1,
          isShow: true,
          text:'只允许按出差单申请金额内预订行程（机票行程申请金额只作为参考）',
          option:[
            {
              value:1,
              label:'是'
            },
            {
              value:0,
              label:'否'
            }
          ]
        },
        {
          id: 2,
          name:'帮订人数上限',
          data:null,
          type:2,
          isShow: true,
          text:'每次出差能够帮多少人一起预定，如果设置为1，表示只能帮自己预定',
          option:[
            {
              value:1,
              label:1
            },
            {
              value:2,
              label:2
            },
            {
              value:3,
              label:3
            },
            {
              value:4,
              label:4
            },
            {
              value:5,
              label:5
            },
            {
              value:6,
              label:6
            }
          ]
        },
        {
          id: 3,
          name:'不限行程',
          data: null,
          type: 1,
          isShow: true,
          text:'发起出差申请时，是否允许选择"不限行程"；允许时，只要出发日期在出差范围内都可以使用该行程单任意预订产品',
          option:[
            {
              value: 1,
              label: '允许选择'
            },
            {
              value: 0,
              label: '禁止选择'
            },
          ]
        },
        {
          id: 4,
          name:'行程时间限制',
          data: null,
          type: 1,
          isShow: true,
          text: '使用出差单预订行程时，是否允许在出差时间范围内任意修改行程出发/入离时间',
          option:[
            {
              value: 1,
              label: '允许修改'
            },
            {
              value: 0,
              label: '禁止修改'
            },
          ]
        },
        {
          id: 5,
          name:'多人出行差标',
          data: null,
          type: 1,
          isShow: true,
          text: '机票/火车票多人出行时，差标限制是取出行中的管控最低差标/取出行人中管控最高差标',
          option:[
            {
              value: 1,
              label: '取最低'
            },
            {
              value: 2,
              label: '取最高'
            },
          ]
        },
        {
          id: 15,
          name:'多人出行酒店差标',
          data: null,
          type: 1,
          isShow: true,
          text: '酒店多人出行时，差标限制是取出行中的管控最低差标/取出行人中管控最高差标',
          option:[
            {
              value: 1,
              label: '取最低'
            },
            {
              value: 2,
              label: '取最高'
            },
            {
              value: 3,
              label: '取差标之和'
            },
          ]
        },
        {
          id: 16,
          name:'酒店超标自付订单退款方式',
          data: null,
          type: 1,
          isShow: true,
          text: '酒店超标自付订单退款方式，按支付比例退款/优先退公司垫付部分/优先退个人垫资部分',
          option:[
              // 酒店超标支付订单退款方式 1：优先退公司垫付部分 2：优先退个人垫资部分 3：按支付比例退款人
            {
              value: 3,
              label: '按支付比例退款'
            },
            {
              value: 1,
              label: '优先退公司垫付部分'
            },
            {
              value: 2,
              label: '优先退个人垫资部分'
            },
          ]
        },
        {
          id: 6,
          name:'机票改签规则',
          data:null,
          type:1,
          isShow: true,
          text:'对机票订单进行改签操作，是否需要先走审批流程',
          option:[
            {
              value:2,
              label:'走审批流'
            },
            {
              value:3,
              label:'仅自愿改签走审批流'
            },
            {
              value:0,
              label:'不限'
            }
          ]
        },
        {
          id: 12,
          name:'火车票改签规则',
          data:null,
          type:1,
          isShow: true,
          text:'对火车票订单进行改签操作，是否需要先走审批流程',
          option:[
            {
              value:2,
              label:'走审批流'
            },
            {
              value:0,
              label:'不限'
            }
          ]
        },
        {
          id: 7,
          name:'机票退订规则',
          data:null,
          type:1,
          isShow: true,
          text:'对机票订单进行退订操作，是否需要先走审批流程',
          option:[
            {
              value:2,
              label:'走审批流'
            },
            {
              value:3,
              label:'仅自愿退票走审批流'
            },
            {
              value:0,
              label:'不限'
            }
          ]
        },
        {
          id: 13,
          name:'火车票退订规则',
          data:null,
          type:1,
          isShow: true,
          text:'对火车票订单进行退订操作，是否需要先走审批流程',
          option:[
            {
              value:2,
              label:'走审批流'
            },
            {
              value:0,
              label:'不限'
            }
          ]
        },
        {
          id: 8,
          name:'酒店驻地差标',
          data:2,
          type:2,
          isShow: true,
          text:'出差天数大于多少天时，使用长期驻地酒店差标',
          option:[
            {
              value:1,
              label:'1天',
            },
            {
              value:2,
              label:'2天',
            },
            {
              value:3,
              label:'3天',
            },
            {
              value:4,
              label:'4天',
            },
            {
              value:5,
              label:'5天',
            },
            {
              value:6,
              label:'6天',
            },
            {
              value:7,
              label:'7天',
            },
            {
              value:8,
              label:'8天',
            },
            {
              value:9,
              label:'9天',
            },
            {
              value:10,
              label:'10天',
            },
            {
              value:11,
              label:'11天',
            },
            {
              value:12,
              label:'12天',
            },
            {
              value:13,
              label:'13天',
            },
            {
              value:14,
              label:'14天',
            },
            {
              value:15,
              label:'15天',
            },
          ]
        },
        {
          id: 9,
          name:'保险管控',
          data:null,
          type:1,
          isShow: true,
          text:'因公出差购买保险的支付方式',
          option:[
            {
              value:1,
              label:'企业支付'
            },
            {
              value:2,
              label:'个人支付'
            }
          ],
          secondData: '',
          secondDataName: '',
          secondOption: [],
        },

        {
          id: 11,
          name: '用车管控',
          data: null,
          type: 1,
          isShow: false,
          text: '因公用车是否一定要申请出差单',
          option:[
            {
              value:1,
              label:'是'
            },
            {
              value:0,
              label:'否'
            }
          ]
        }
      ],
      rules: {
        secondData: [
          { required: true, message: '请选择保障包', trigger: 'blur' },
        ]
      },
      ifLongShortTrip: null,
      businessRuleControl: '1', // 因公出差规则 1：出差单审批 2：预订审批
      showSubsidyControlRule: 0,
      formData:{
        evectionRequired: null,
        bookUpperBound: null,

        unlimitedJourney: null,
        allowChangeJourneyTime: null,
        policyControlRule: null,
        hotelPolicyControlRule: null,

        changePolicyType: null,
        refundPolicyType: null,
        changePolicyTypeTrain: null,
        refundPolicyTypeTrain: null,
        hotelLocationPolicy: null,

        insuranceControl: null,
        productCode: '',
        productName: '',

        carControlRule: null,
        subsidyControlRule: null,

        hotelRefundTypeOutStandard: null,
      }
    };
  },
  components: { DialogBox },
  mounted() {
  },
  activated() {
    this.getData()
  },
  methods: {
    async getData() {
      let [checkErr, checkRes] = await awaitWrap(consumer_web_company_checkCompanyUseCar());
      if (checkErr) {
        return
      }
      const useCar = checkRes.datas.useCar;
      this.dataTest.forEach((item, index) => {
        item.data = 2;
        if (item.id === 1) {
          item.data = 1;
        }
        if (item.id === 9) {
          item.secondData = ''
          item.secondDataName = ''
        }
        item.isShow = true;
        if (item.id === 11) {
          useCar === 1 ? item.isShow = true : item.isShow = false
        }
      });
      let [err, data] = await awaitWrap(consumer_t_tp_refund_change_policy_get());
      if (err) {
        return
      }
      this.detail = data.datas;
      this.showSubsidyControlRule = this.detail.showSubsidyControlRule;
      this.businessRuleControl = data.datas.businessRuleControl.toString();
      let businessRule = this.dataTest.find(item => {
        return item.id === 10
      })
      if (this.businessRuleControl === '2' && !businessRule) {
        this.dataTest.unshift(
            {
              id: 10,
              name: '因公出差规则',
              data: '2',
              type: 1,
              text: '因公出差出差单审批或预订审批',
              option: [
                {
                  value: '1',
                  label: '出差单审批'
                },
                {
                  value: '2',
                  label: '预订审批'
                }
              ] // 1：出差单审批 2：预订审批
            },
        );
      }

      this.ifLongShortTrip = data.datas.ifLongShortTrip;

      //将获取的数据存进this.dataTest中渲染到页面
      this.dataTest.forEach(item => {
        if (item.id === 1) {
          item.data = data.datas.evectionRequired;
          item.isShow = data.datas.businessRuleControl.toString() === '1';
        }
        if (item.id === 2) {
          item.data = data.datas.bookUpperBound;
        }
        if (item.id === 3) {
          item.data = data.datas.unlimitedJourney;
        }
        if (item.id === 4) {
          item.data = data.datas.allowChangeJourneyTime;
        }
        if (item.id === 5) {
          item.data = data.datas.policyControlRule;
        }
        if (item.id === 6) {
          item.data = data.datas.changePolicyType;
        }
        if (item.id === 7) {
          item.data = data.datas.refundPolicyType;
        }
        if (item.id === 8) {
          item.data = data.datas.hotelLocationPolicy;
        }
        if (item.id === 9) {
          item.data = data.datas.insuranceControl;
          item.secondData = data.datas.productCode || '';
          item.secondDataName = data.datas.productName || '';
        }
        if (item.id === 10) {
          item.data = data.datas.businessRuleControl.toString();
          item.isShow = item.data === '1' ? false : true;
        }
        if (item.id === 11) {
          item.data = data.datas.carControlRule;
        }
        if (item.id === 12) {
          item.data = data.datas.changePolicyTypeTrain;
        }
        if (item.id === 13) {
          item.data = data.datas.refundPolicyTypeTrain;
        }
        if (item.id === 14) {
          item.data = data.datas.subsidyControlRule;
          if (this.showSubsidyControlRule == 1) {
            item.isShow = true;
          } else {
            item.isShow = false;
          }
        }
        if (item.id === 15) {
          item.data = data.datas.hotelPolicyControlRule;
        }
        if (item.id === 16) {
          item.data = data.datas.hotelRefundTypeOutStandard;
        }
      })

      /*if (this.ifLongShortTrip !== 1) {
        let idx = 0;
        this.dataTest.find((item, index) => {
          idx = index;
          return item.id === 8
        })
        console.log(this.dataTest);
        this.dataTest.splice(idx, 1);

      }*/
      this.dataTest.forEach(value => {
        if (value.id === 8) {
          value.isShow = this.ifLongShortTrip !== 1 ? false : true;
        }
      })

      //将数据存进数据提交模板
      this.formData.businessRuleControl = data.datas.businessRuleControl;
      this.formData.evectionRequired = data.datas.evectionRequired;
      this.formData.bookUpperBound = data.datas.bookUpperBound;

      this.formData.unlimitedJourney = data.datas.unlimitedJourney;
      this.formData.allowChangeJourneyTime = data.datas.allowChangeJourneyTime;
      this.formData.policyControlRule = data.datas.policyControlRule;
      this.formData.hotelPolicyControlRule = data.datas.hotelPolicyControlRule;

      this.formData.changePolicyType = data.datas.changePolicyType;
      this.formData.refundPolicyType = data.datas.refundPolicyType;
      if (this.ifLongShortTrip == 1) {
        this.formData.hotelLocationPolicy = data.datas.hotelLocationPolicy;
      }
      this.formData.insuranceControl = data.datas.insuranceControl;
      this.formData.productCode = data.datas.productCode;
      this.formData.productName = data.datas.productName;
      this.formData.changePolicyTypeTrain = data.datas.changePolicyTypeTrain;
      this.formData.refundPolicyTypeTrain = data.datas.refundPolicyTypeTrain;

      this.formData.carControlRule = data.datas.carControlRule;
      this.formData.subsidyControlRule = data.datas.subsidyControlRule;
      this.formData.hotelRefundTypeOutStandard = data.datas.hotelRefundTypeOutStandard;
      console.log(this.dataTest)
      this.getInsuranceProductList()
    },
    getInsuranceProductList() {
      consumer_admin_flight_generate_getInsuranceProductList().then(res => {
        let insuranceProductVoList = res.datas.insuranceProductVoList;
        this.dataTest.forEach(item => {
          if (item.id === 9) {
            item.secondOption = insuranceProductVoList;
          }
        })
      })
    },
    clickOff () {
      //切换
      this.dataOff = !this.dataOff;
    },
    checkForm() {
      return new Promise(resolve => {
        this.$refs['form9'][0].validate((valid) => {
          resolve(valid)
        })
      })
    },
    //保存
    async clickKeep () {
      let checkResult = await this.checkForm();
      if (!checkResult) {
        return
      }
      //将this.dataTest中选择的数据存进this.formData
      this.formData.hotelLocationPolicy = '0';
      this.formData.businessRuleControl = '1';
      this.dataTest.forEach(item => {
        if (item.id === 1) {
          this.formData.evectionRequired = `${item.data}`;
        }
        if (item.id === 2) {
          this.formData.bookUpperBound = `${item.data}`;
        }
        if (item.id === 3) {
          this.formData.unlimitedJourney = `${item.data}`;
        }
        if (item.id === 4) {
          this.formData.allowChangeJourneyTime = `${item.data}`;
        }
        if (item.id === 5) {
          this.formData.policyControlRule = item.data + '';
        }
        if (item.id === 6) {
          this.formData.changePolicyType = `${item.data}`;
        }
        if (item.id === 7) {
          this.formData.refundPolicyType = `${item.data}`;
        }
        if (item.id === 8) {
          this.formData.hotelLocationPolicy = `${item.data}`;
        }
        if (item.id === 9) {
          this.formData.insuranceControl = `${item.data}`;
          this.formData.productCode = `${item.secondData}`;
          this.formData.productName = item.secondDataName;
        }
        if (item.id === 10) {
          this.formData.businessRuleControl = `${item.data}`;
          item.isShow = item.data === '1' ? false : true;
        }
        if (item.id === 11) {
            this.formData.carControlRule = `${item.data}`;
        }
        if (item.id === 12) {
          this.formData.changePolicyTypeTrain = `${item.data}`;
        }
        if (item.id === 13) {
          this.formData.refundPolicyTypeTrain = `${item.data}`;
        }
        if (item.id === 14) {
          this.formData.subsidyControlRule = `${item.data}`;
        }
        if (item.id === 15) {
          this.formData.hotelPolicyControlRule = item.data + '';
        }
        if (item.id === 16) {
          this.formData.hotelRefundTypeOutStandard = item.data;
        }
      })

      //访问接口进行数据提交
      consumer_t_tp_refund_change_policy_saveEvectionPolicy(this.formData)
        .then((data) => {
          this.$notify({
            title: '成功',
            message: '保存成功',
            duration:2000,
            type: 'success'
          });

          this._refund_hange_policy_get()
        })
        .catch((err) => {
          console.log(err);
          this.$notify.error({
            title: '错误',
            message: '保存出错',
            duration:2000,
          });
        })

      this.clickOff();
    },
    //取消
    clickCancel () {
      this.clickOff();
      //单击取消，将this.formData保存的数据存入this.dataTest达到回退的效果
      this.dataTest.forEach(item => {
        if (item.id === 1) {
          item.data = parseInt(this.formData.evectionRequired);
        }
        if (item.id === 2) {
          item.data = parseInt(this.formData.bookUpperBound);
        }
        if (item.id === 3) {
          item.data = parseInt(this.formData.unlimitedJourney);
        }
        if (item.id === 4) {
          item.data = parseInt(this.formData.allowChangeJourneyTime);
        }
        if (item.id === 5) {
          item.data = parseInt(this.formData.policyControlRule);
        }
        if (item.id === 6) {
          item.data = parseInt(this.formData.changePolicyType);
        }
        if (item.id === 7) {
          item.data = parseInt(this.formData.refundPolicyType);
        }
        if (item.id === 8) {
          item.data = parseInt(this.formData.hotelLocationPolicy);
        }
        if (item.id === 9) {
          item.data = parseInt(this.formData.insuranceControl);
          item.secondData = this.formData.productCode;
          item.secondDataName = this.formData.productName;
        }
        if (item.id === 10) {
          item.data = parseInt(this.formData.businessRuleControl);
          item.isShow = item.data === '1' ? false : true;
        }
        if (item.id === 11) {
          item.data = parseInt(this.formData.carControlRule);
        }
        if (item.id === 12) {
          item.data = parseInt(this.formData.changePolicyTypeTrain);
        }
        if (item.id === 13) {
          item.data = parseInt(this.formData.refundPolicyTypeTrain);
        }
        if (item.id === 14) {
          item.data = parseInt(this.formData.subsidyControlRule);
        }
        if (item.id === 15) {
          item.data = parseInt(this.formData.hotelPolicyControlRule);
        }
        if (item.id === 16) {
          item.data = parseInt(this.formData.hotelRefundTypeOutStandard);
        }
      })
    },
    changeProduct(val) {
      let secondDataName = ''
      this.dataTest.forEach(value => {
        if (value.id === 9) {
          value.secondOption.forEach(value1 => {
            if (value1.productCode === val) {
              secondDataName = value1.packageName
            }
          })
          value.secondDataName = secondDataName
        }
      })

    },
    changeRadio(item, val) {
      console.log(item, val);
      if (item.id === 10) {
          this.dataTest.forEach(value => {
            if (value.id === 1) {
                value.isShow = val === '1';
                value.data = 0;
            }
          })
      }
    },


    /**
     *
     * @param {*} type 1:机票 2:火车票
     */
    _getDialogShow(type) {
      this.dialogShow = true;
      this.dialogType = type;
      let _data = this.formData;
      if (type === 1) {
        _data.refund = this.detail.airRefund;
        _data.ticket = this.detail.airChange;
      } else {
        _data.refund = this.detail.carRefund;
        _data.ticket = this.detail.carChange;
      }
    },
    /**
     *  datas: 0:不限, 10:出差行程变化, 20:天气因素, 30:定错机票, 40:定错车次
     */
    _refund_hange_policy_get() {
      consumer_t_tp_refund_change_policy_get().then(res => {
        this.detail = res.datas;
        this.showSubsidyControlRule = this.detail.showSubsidyControlRule;
        this.dataTest.forEach(item => {
          if (item.id === 14) {
            if (this.showSubsidyControlRule == 1) {
              item.isShow = true;
            } else {
              item.isShow = false;
            }
          }
        });
        this.businessRuleControl = res.datas.businessRuleControl.toString();
          let businessRule = this.dataTest.find(item => {
              return item.id === 10
          });
        if (this.businessRuleControl === '2' && !businessRule) {
          this.dataTest.unshift(
              {
                id: 10,
                name: '因公出差规则',
                data: '2',
                type: 1,
                text: '因公出差出差单审批或预订审批',
                option: [
                  {
                    value: '1',
                    label: '出差单审批'
                  },
                  {
                    value: '2',
                    label: '预订审批'
                  }
                ] // 1：出差单审批 2：预订审批
              },
          );
        }
      })
    }
  }
}
